
<div class="container"><br>
  <div class="row justify-content-md-center">
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h1>Authentication</h1>
        </div>
        <div class="panel-body">
          <button class="btn btn-primary btn-lg btn-block" (click)="login()">Login</button>
          <hr>
          <div class="centerize">
            <button class="btn btn-link btn-lg" (click)="cancelLogin()">Continue without logging in</button>
          </div>
          <h2 *ngIf="devEnabled">Dev</h2>
          <form *ngIf="devEnabled">
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" name="secret" type="password" placeholder="Change secret" #secret>
                <div class="input-group-btn">
                  <button class="btn btn-default form-control" type="button" (click)="setSecret(secret.value)">Change</button>
                </div>
                <div class="input-group-btn">
                  <button class="btn btn-primary form-control" type="button" (click)="devLogin()">Dev Login</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>