import {Observable} from "rxjs/Observable";
import {AuthTokenService} from "./auth-token.service";
import {ApiService} from "../api.service";
import {AppUser} from "./appuser";
import {AuthService} from "./auth.service";
import {EventEmitter, Injectable, OnInit} from '@angular/core';
import {MetadataService, User, UserManager, WebStorageStateStore} from 'oidc-client';

@Injectable()
export class UserService implements OnInit {
  userLoadedEvent: EventEmitter<AppUser> = new EventEmitter<AppUser>();
  private loadedUser: User;
  private realUser: Promise<AppUser>;
  private devUser: Promise<AppUser>;
  private currentUser: AppUser;

  constructor(private authService: AuthService, private apiService: ApiService,
              private authTokenService: AuthTokenService) {
  }

  ngOnInit() {
    this.authService.userLoadedEvent.subscribe(user => {
      if (user && user.access_token && !user.expired) {
        this.loadRealUser(user).then(x => this.userLoadedEvent.emit(x));
      } else {
        this.userLoadedEvent.emit(null);
      }
    });
    this.userLoadedEvent.subscribe();
  }

  private logout() {
    this.loadedUser = null;
    this.realUser = null;
    this.devUser = null;
    this.userLoadedEvent.emit(null);
  }

  private loadRealUser(user: User) {
    if ((!this.loadedUser || this.loadedUser.profile.oid !== user.profile.oid) && !user.expired) {
      this.loadedUser = user;
      this.realUser = this.fetchRealUser(user.profile.oid);
    }
    return this.realUser;
  }

  private fetchRealUser(oid: string) {
    return this.apiService.get(`api/sys/Users('${oid}')`)
      .map(x => x.json())
      .map(x => ({
        name: x.name,
        key: x.employee_key,
        roles: x.roles
      })).catch(() => {
        this.logout();
        return Observable.of(null);
      }).toPromise();
  }

  private loadDevUser() {
    if (!this.devUser) {
      this.devUser = this.fetchDevUser();
    }
    return this.devUser;
  }

  private fetchDevUser() {
    return this.apiService.get(`api/sys/Users`)
      .map(x => x.json().value[0])
      .map(x => ({
        name: x.name,
        key: x.employee_key,
        roles: x.roles
      })).catch(() => {
        this.logout();
        return Observable.of(null);
      }).toPromise();
  }

  private loadUser(): Promise<AppUser> {
    return this.authService.getManagerUser()
      .then(user => {
        if (user) {
          return this.loadRealUser(user);
        } else if (this.authTokenService.isDev()) {
          return this.loadDevUser();
        } else {
          return null;
        }
      });
  }

  getUser() {
    return this.loadUser().then(x => this.currentUser = x);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  devLogin() {
    this.devUser = null;
    this.loadDevUser().then(x => this.userLoadedEvent.emit(x));
  }
}
