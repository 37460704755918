import {JwtHelper} from "angular2-jwt";

const jwtHelper = new JwtHelper();

export class JwtDecodeService {
  
  static getProfile(accessToken: string): any {
    var decodedToken = jwtHelper.decodeToken(accessToken);
    if (decodedToken && decodedToken.oid) {
      var profile: {[k: string]: any} = {};
      profile.oid = decodedToken.oid;
      profile.name = decodedToken.name;
      if (decodedToken.given_name) {
        profile.given_name = decodedToken.given_name;
      }
      if (decodedToken.family_name) {
        profile.family_name = decodedToken.family_name;
      }
      if (decodedToken.upn) {
        profile.email = decodedToken.upn;
      }
      if (decodedToken.preferred_username) {
        profile.preferred_username = decodedToken.preferred_username;
      }
      return profile;
    } else {
      return null;
    }
  }
  
}