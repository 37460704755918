import {ReplaySubject} from "rxjs/ReplaySubject";
import {Injectable} from '@angular/core';

@Injectable()
export class RouteNameService {
  private subj = new ReplaySubject<string>(1);
  routeName = this.subj.asObservable();

  constructor() {
    this.subj.next('');
  }

  activate(routeName: string) {
    this.subj.next(routeName);
  }
}
