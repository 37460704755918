import {Component} from '@angular/core';
import {RouteNameService} from "./route/route-name.service";

@Component({
  selector: 'my-app',
  templateUrl: 'app.component.html',
  moduleId: module.id,
  styleUrls: ['app.component.css']
})
export class AppComponent {
  title = 'Nortal Apps';
  routeName: string;

  constructor(private routeNameService: RouteNameService) {
    this.getRouteName();
  }

  getRouteName() {
    return this.routeNameService.routeName.subscribe(name => this.routeName = name)
  }

}
