
<div class="nav-sidebar">
  <h1 class="sr-only">Menu</h1>
  <div class="nav-sidebar__logo"><a>
      <div class="nav-sidebar__link">Apps</div></a></div>
  <button class="nav-sidebar__toggle collapsed" type="button" name="collapse" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i class="fa fa-bars"></i></button>
  <div class="collapse navbar-collapse" id="navbar">
    <ul class="nav-sidebar__navs">
      <li class="nav-sidebar__item" *ngFor="let route of routes"><a class="nav-sidebar__link" routerLink="{{route.path}}" routerLinkActive="active" tooltip="{{route.name}}" placement="right"><i class="fa fa-2x" aria-hidden="true" [ngClass]="'fa-' + route.icon"></i><span class="text-capitalize">{{route.name}}</span></a></li>
    </ul>
  </div>
</div>