
<div class="main__header__title">
  <div class="main__header__heading">
    <!--span(style="padding-left: 10px") {{routeName$ | async}}-->
  </div>
</div>
<div>
  <div class="main__header__user">
    <div class="main__header__user__wrapper">
      <user-status></user-status>
    </div>
  </div>
</div>