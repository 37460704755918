import {Component, OnInit} from '@angular/core';
import {AppsService} from "./apps.service";
import {UserService} from "../auth/user.service";
import {AppUser} from "../auth/appuser";

@Component({
  selector: 'apps',
  templateUrl: 'apps.component.html',
  moduleId: module.id,
})
export class AppsComponent implements OnInit {
  user: AppUser;
  apps: any[] = [];

  constructor(private userService: UserService, private appsService: AppsService) {
  }

  ngOnInit(): void {
    this.getUser().then(user => {
      this.user = user;
      this.getApps();
    })
  }

  openLink(app: any) {
    if (app.url) {
      this.openExternalUrl(app.url);
    }
  }

  isAppHidden(app: any): boolean {
    let hiddenApp = this.appsService.hiddenApps.find(hiddenApp => hiddenApp.code == app.DataSourceSystem.code);
    return hiddenApp && (!this.user || !this.hasUserRequiredRole(hiddenApp));
  }

  private getUser() {
    return this.userService.getUser();
  }

  private getApps() {
    this.appsService.getApps().subscribe(apps => this.apps = this.sortApps(apps));
  }

  private openExternalUrl(link) {
    window.open(link, '_self');
  }

  private sortApps(apps) {
    return apps.sort((a, b) => (a.DataSourceSystem.name > b.DataSourceSystem.name) ? 1 : ((b.DataSourceSystem.name > a.DataSourceSystem.name) ? -1 : 0))
  }

  private hasUserRequiredRole(app: any) {
    return !!app.allowedRoles.find(role => this.user.roles.includes(role));
  }
}
