import {AuthTokenService} from "../auth/auth-token.service";
import {Router} from "@angular/router";
import {UserService} from "../auth/user.service";
import {AuthService} from "../auth/auth.service";
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'user-status',
  templateUrl: 'user-status.component.html',
  moduleId: module.id,
})
export class UserStatusComponent implements OnInit {
  public loggedin = false;
  user: any;

  constructor(private authService: AuthService,
              private userService: UserService,
              private authTokenService: AuthTokenService,
              private router: Router) {
  }

  ngOnInit() {
    this.userService.getUser().then(user => this.user = user);
    this.userService.userLoadedEvent.subscribe(user => {
      this.user = user;
    })
  }

  login() {
    this.authService.startSigninMainWindow();
  }

  logout() {
    this.authTokenService.setDev(false);
    this.authService.logout();
    this.userService.userLoadedEvent.emit(null);
    this.router.navigate(['/login'])
  }
}
