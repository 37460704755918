
<div class="container-fluid"><br>
  <div class="row">
    <ng-container *ngFor="let app of apps">
      <div class="app col-xs-12 col-md-6 col-lg-3" *ngIf="!isAppHidden(app)" title="{{app.DataSourceSystem.name}}">
        <div class="panel panel-default" (click)="openLink(app)">
          <div class="panel-heading">
            <button class="btn btn-primary pull-left panel-button" type="button"><i class="fa fa-2x" [ngClass]="'fa-' + app.icon_name"></i></button>
            <h2>{{app.DataSourceSystem.name}}</h2>
            <h5>{{app.DataSourceSystem.description}}</h5>
            <h6> <a href="{{app.url}}">{{app.url}}</a></h6>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>