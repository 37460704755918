import {RouteNameService} from "./route-name.service";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from '@angular/core';

@Injectable()
export class RouteNameResolve implements Resolve<string> {

  constructor(private routeNameService: RouteNameService) {
  }

  resolve(route: ActivatedRouteSnapshot): string {
    let routeName = (route.routeConfig as any).name;
    if (routeName) {
      this.routeNameService.activate(routeName);
    }
    return routeName;
  }
}
