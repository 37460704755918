import {AppsComponent} from "./apps/apps.component";
import {HeaderComponent} from "./header/header.component";
import {MainMenuComponent} from "./main-menu/main-menu.component";
import './rxjs-extensions';
import {HttpModule} from "@angular/http";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouteNameService} from "./route/route-name.service";
import {RouteNameResolve} from "./route/route-name.resolve";
import {BsDropdownModule, TooltipModule} from "ngx-bootstrap";
import {LoginComponent} from "./login/login.component";
import {ApiService} from "./api.service";
import {AuthTokenService} from "./auth/auth-token.service";
import {UserService} from "./auth/user.service";
import {AuthService} from "./auth/auth.service";
import {SecretService} from "./auth/secret.service";
import {UserStatusComponent} from "./user-status/user-status.component";
import {MainComponent} from "./main/main.component";
import {AppsService} from "./apps/apps.service";

@NgModule({
  imports: [BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  declarations: [AppComponent,
    MainComponent,
    HeaderComponent,
    MainMenuComponent,
    AppsComponent,
    LoginComponent,
    UserStatusComponent],
  providers: [
    AuthService,
    UserService,
    SecretService,
    AuthTokenService,
    ApiService,
    RouteNameService,
    RouteNameResolve,
    AppsService,
    {provide: LOCALE_ID, useValue: "et-EE"}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
