import {AuthTokenService} from "../auth/auth-token.service";
import {UserService} from "../auth/user.service";
import {SecretService} from "../auth/secret.service";
import {AuthService} from "../auth/auth.service";
import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  moduleId: module.id,
})
export class LoginComponent implements OnInit {
  dev = false;
  devEnabled = false;

  constructor(private authService: AuthService,
              private secretService: SecretService,
              private userService: UserService,
              private authTokenService: AuthTokenService,
              private router: Router) {
  }

  ngOnInit() {
    this.dev = this.authTokenService.isDev();
    this.devEnabled = this.isDevEnabled((window as any).portalConfig.env);
  }

  private isDevEnabled(env: any) {
    return env == 'dev' || env == 'test';
  }

  login() {
    this.authTokenService.setDev(false);
    this.authService.startSigninMainWindow();
  }

  setSecret(text) {
    this.secretService.setSecret(text);
  }

  setDev(dev: boolean) {
    this.dev = dev;
    this.authTokenService.setDev(dev);
  }

  devLogin() {
    this.authTokenService.setDev(true);
    this.userService.devLogin();
    this.router.navigate(['/portal']);
  }

  cancelLogin() {
    this.router.navigate(['/portal']);
  }
}
