import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class SecretService {
  needsLogin = new EventEmitter<string>();

  constructor() {
  }

  getSecret() {
    return localStorage.getItem('portal-secret');
  }

  setSecret(secret: string) {
    localStorage.setItem('portal-secret', secret);
    if (!secret) {
      this.needsLogin.emit("bla");
    }
  }

  removeSecret() {
    localStorage.removeItem('portal-secret');
  }
}
