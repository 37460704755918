import {Component, OnInit} from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'page-main',
  templateUrl: 'main.component.html',
  moduleId: module.id
})
export class MainComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.authService.loadUser().then(user => this.redirect(user));
  }

  private redirect(user) {
    this.router.navigate(user ? ['/portal'] : ['/login'])
  }
}
