import {Component} from '@angular/core';
import {RouteConfig} from "../route/route.config";

@Component({
  selector: 'main-menu',
  templateUrl: 'main-menu.component.html',
  moduleId: module.id
})
export class MainMenuComponent {
  routes = RouteConfig.menuRoutes;
}
