import {EventEmitter, Injectable} from '@angular/core';
import {Log, MetadataService, User, UserManager, WebStorageStateStore} from 'oidc-client';
import {JwtDecodeService} from "./jwt-decode.service";
import Timer = NodeJS.Timer;

@Injectable()
export class AuthService {
  manager = new UserManager(getOidcConfig());
  userLoadedEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User;
  loggedIn: boolean = false;

  tokenCheckInterval: Timer;
  checkIntervalTime = 600000; //10 min in ms

  constructor() {
    Log.logger = console;

    //Work around https://github.com/IdentityModel/oidc-client-js/issues/227
    (this.manager as any)._settings._validator
      ._metadataService._filterSigningKeys = (x: any) => x;

    this.loadUser();

    this.manager.events.addUserUnloaded(() => {
      this.removeTokenCheckInterval();
      this.loggedIn = false;
      this.currentUser = null;
      this.userLoadedEvent.emit(null);
    });

    this.manager.events.addUserLoaded(user => {
      this.currentUser = this.getValidUser(user);
      if (this.currentUser && this.currentUser.profile) {
        this.loggedIn = true;
        this.userLoadedEvent.emit(this.currentUser);
      }
    });
  }

  loadUser() {
    return this.getManagerUser().then(user => {
      if (user) {
        if (user.expired) {
          this.logout();
          return undefined;
        }
        this.loggedIn = true;
        this.currentUser = user;
        this.userLoadedEvent.emit(this.currentUser);
        this.setTokenCheckInterval();
        return user;
      }
    });
  }

  getManagerUser():Promise<any> {
    return this.manager.getUser().then(user => {
      return this.getValidUser(user);
    });
  }

  private getValidUser(user) {
    if(user && user.access_token) {
      user.profile = JwtDecodeService.getProfile(user.access_token);
      return this.getUserWithProfile(user);
    } else {
      return null;
    }
  }
  
  
  private getUserWithProfile(user): any {
    if (user.profile && user.profile.oid) {
      return user;
    } else {
      return null;
    }
  }

  setTokenCheckInterval() {
    this.tokenCheck();
    this.tokenCheckInterval = setInterval(this.tokenCheck, this.checkIntervalTime)
  }

  removeTokenCheckInterval() {
    this.tokenCheckInterval ? clearInterval(this.tokenCheckInterval) : undefined;
  }

  tokenCheck() {
    const renewTime = 2700;
    if (!this.manager) {
      this.manager = new UserManager(getOidcConfig());
      this.userLoadedEvent = new EventEmitter<User>();
    }
    this.manager.getUser().then(user => {
      if (user && !user.expired && user.expires_in < renewTime) {
        this.manager.signinSilent().then(user => {
          this.currentUser = this.getValidUser(user);
          this.loggedIn = (this.currentUser != null);
          this.userLoadedEvent.emit(this.currentUser);
        });
      }
    });
  }

  startSigninMainWindow() {
    return this.manager.signinRedirect();
  }

  logout() {
    return this.manager.removeUser();
  }

  getUser() {
    return this.currentUser;
  }
}

function getOidcConfig() {
  const portalConfig = (window as any).portalConfig;
  const oidc = portalConfig.oidc;
  oidc.userStore = new WebStorageStateStore({store: window.localStorage});
  return {
    authority: oidc.authority,
    client_id: oidc.client_id,
    redirect_uri: oidc.redirect_base + "/auth.html",
    silent_redirect_uri: oidc.redirect_base + "/silent-renew.html",
    automaticSilentRenew: false,
    userStore: oidc.userStore,
    scope: oidc.scope,
    response_type: oidc.response_type,
    loadUserInfo: false,
    logout: oidc.logout
  };
}
