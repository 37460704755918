import {RouteNameService} from "../route/route-name.service";
import {Component} from '@angular/core';
import {Observable} from "rxjs/Observable";

@Component({
  selector: 'header',
  templateUrl: 'header.component.html',
  moduleId: module.id
})
export class HeaderComponent {
  routeName$: Observable<string>;
  routeName: string;

  constructor(private routeNameService: RouteNameService) {
    this.routeName$ = routeNameService.routeName;
    this.routeName$.subscribe(name => this.routeName = name)
  }
}
