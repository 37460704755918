import {RouteNameResolve} from "./route-name.resolve";
import {AppsComponent} from "../apps/apps.component";
import {LoginComponent} from "../login/login.component";
import {MainComponent} from "../main/main.component";

export class RouteConfig {

  // XXX Repeating resolve block manually is ugly but currently there is no good way to automate it because aot does not
  // allow functions in route definitions.
  static routes = [
    {path: '', redirectTo: '/', pathMatch: 'full'},
    {
      path: '',
      component: MainComponent,
      resolve: {
        routeName: RouteNameResolve
      }
    },
    {
      path: 'portal',
      component: AppsComponent,
      name: 'Apps',
      icon: 'home',
      resolve: {
        routeName: RouteNameResolve
      }
    },
    {
      path: 'login',
      component: LoginComponent,
      name: 'Login',
      resolve: {
        routeName: RouteNameResolve
      }
    }
  ];

  static menuRoutes = ['portal'].map(x => RouteConfig.routes.find(y => y.path === x));
}
