import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {Observable} from "rxjs/Observable";

@Injectable()
export class AppsService {
  hiddenApps: any[] = [{
    code: 'MDM',
    allowedRoles: ['MDM admin']
  }];

  constructor(private api: ApiService) {
  }

  getApps(): Observable<any> {
    return this.api.get(`api/inf/PortalApps?$expand=DataSourceSystem`)
      .map(response => response.json().value as any[]);
  }
}
