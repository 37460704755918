import {AuthTokenService} from "./auth/auth-token.service";
import {Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend} from "@angular/http";
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable()
export class ApiService extends Http {

  constructor(xhrBackend: XHRBackend, requestOptions: RequestOptions,
              private authTokenService: AuthTokenService, private router: Router) {
    super(xhrBackend, requestOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs) {
    if (url instanceof Request) {
      return Observable.fromPromise(this.authTokenService.getTokens())
        .switchMap(tokens => {
          if (tokens) {
            url.headers.append("Authorization", "Bearer " + tokens.token);
            if (tokens.idtoken) {
              url.headers.append("IdToken", tokens.idtoken);
            }
          }
          return this.doRequest(url, options);
        });
    } else {
      return this.doRequest(url, options);
    }
  }

  private doRequest(url: string | Request, options?: RequestOptionsArgs) {
    return super.request(url, options).catch(this.catchError);
  }

  private catchError = (error: any, caught: Observable<Response>) => {
    if (error.status === 401) {
      this.router.navigate(['/login']);
    }
    return Observable.throw(error);
  }
}
