import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {AuthTokens} from "./auth-tokens";
import {SecretService} from "./secret.service";

@Injectable()
export class AuthTokenService {
  constructor(private authService: AuthService, private secretService: SecretService) {
  }

  public getTokens(): Promise<AuthTokens> {
    if (this.isDev()) {
      return Promise.resolve({
        token: this.secretService.getSecret()
      });
    } else {
      return this.authService.manager.getUser()
        .then(user => user ? ({
          token: user.access_token
        }) : null);
    }
  }

  isDev() {
    return !!localStorage.getItem('portal-dev');
  }

  setDev(dev: boolean) {
    if (dev) {
      localStorage.setItem('portal-dev', "dev");
    } else {
      localStorage.removeItem('portal-dev');
      this.secretService.removeSecret();
    }
  }
}
